<template>
<div class="min-h-screen bg-gradient-to-br from-gray-900 to-black flex items-center justify-center p-5">
<div class="w-full max-w-md overflow-hidden bg-white rounded-xl shadow-2xl">
<div class="relative bg-gradient-to-r from-yellow-600 to-yellow-500 p-8 text-center">
<div class="absolute top-0 left-0 w-full h-full opacity-10 bg-[url('/assets/img/business-4/icon.avif')] bg-center bg-repeat-space"></div>
<img src=/assets/img/business-4/icon.avif alt=Logo class="h-20 w-20 mx-auto mb-3">
<h2 class="text-gray-900 text-2xl font-bold">Welcome Back</h2>
<p class="text-gray-800 text-sm mt-1">Sign in to continue to dashboard</p>
</div>
<div class=p-8>
<form @submit.prevent=login>
<div class=mb-6>
<label for=username class="block text-sm font-medium text-gray-700 mb-1">Username</label>
<div class=relative>
<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
<i class="fas fa-user text-gray-400"></i>
</div>
<input id=username v-model=username placeholder="Enter your username" class="w-full pl-10 pr-3 py-3 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition-all" required>
</div>
</div>
<div class=mb-6>
<div class="flex justify-between mb-1">
<label for=password class="block text-sm font-medium text-gray-700">Password</label>
<a href=# class="text-sm text-yellow-600 hover:text-yellow-800">Forgot Password?</a>
</div>
<div class=relative>
<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
<i class="fas fa-lock text-gray-400"></i>
</div>
<input type=password id=password v-model=password placeholder="Enter your password" class="w-full pl-10 pr-3 py-3 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition-all" required autocomplete=current-password>
<div class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" @click=togglePassword>
<i class=fas :class="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
</div>
</div>
</div>
<div class="flex items-center mb-6">
<input type=checkbox id=remember class="w-4 h-4 text-yellow-600 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500">
<label for=remember class="ml-2 text-sm text-gray-700">Remember me</label>
</div>
<div v-if=errorMessage class="mb-6 p-3 bg-red-50 border-l-4 border-red-500 text-red-700 rounded">
<div class=flex>
<div class=flex-shrink-0>
<i class="fas fa-exclamation-circle"></i>
</div>
<div class=ml-3>
<p class=text-sm>{{ errorMessage }}</p>
</div>
</div>
</div>
<button type=submit :disabled="!isValidForm || loading" class="w-full py-3 px-4 bg-gradient-to-r from-yellow-600 to-yellow-500 hover:from-yellow-700 hover:to-yellow-600 text-gray-900 font-medium rounded-lg shadow-md hover:shadow-lg transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:opacity-50 disabled:cursor-not-allowed">
<div class="flex justify-center items-center">
<i v-if=loading class="fas fa-spinner fa-spin mr-2"></i>
<span>{{ loading ? 'Signing In...' : 'Sign In' }}</span>
</div>
</button>
</form>
</div>
<div class="px-8 py-4 bg-gray-50 border-t border-gray-100 text-center">
<div class="flex justify-center space-x-4">
<a href=# class="text-gray-400 hover:text-yellow-600 transition-colors">
<i class="fab fa-facebook-f"></i>
</a>
<a href=# class="text-gray-400 hover:text-yellow-600 transition-colors">
<i class="fab fa-twitter"></i>
</a>
<a href=# class="text-gray-400 hover:text-yellow-600 transition-colors">
<i class="fab fa-linkedin-in"></i>
</a>
</div>
<p class="mt-2 text-xs text-gray-500">© 2025 Pura MICS. All rights reserved.</p>
</div>
</div>
</div>
</template>
<script>
import e from"axios";import s from"@/store";export default{name:"LoginDs",data:()=>({username:"",password:"",errorMessage:"",loading:!1,isValidForm:!1,showPassword:!1}),watch:{username(){this.validateForm()},password(){this.validateForm()}},mounted(){this.enableTailwind(),document.body.classList.add("dashboard-page"),this.$nextTick(()=>{document.getElementById("username").focus()})},beforeUnmount(){document.body.classList.remove("dashboard-page")},methods:{enableTailwind(){let e=document.querySelector('link[href*="tailwindcss"]');if(e)e.disabled=!1;else{let s=document.createElement("link");s.rel="stylesheet",s.href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css",document.head.appendChild(s)}},validateForm(){this.isValidForm=!!this.username&&!!this.password},togglePassword(){this.showPassword=!this.showPassword;let e=document.getElementById("password");e.type=this.showPassword?"text":"password"},async login(){this.errorMessage="",this.loading=!0;try{let r=await e.post("https://puracigarettesolutions.com:3000/api/users/login",{username:this.username,password:this.password});r.data.success?(this.$el.querySelector('button[type="submit"]').innerHTML='<i class="fas fa-check-circle mr-2"></i> Success!',localStorage.setItem("token",r.data.token),s.commit("SET_LOGGED_IN",!0),s.commit("SET_USER_ROLE",r.data.role),setTimeout(()=>{let e=this.$route.query.redirect||{name:"DashboardDs"};this.$router.push(e)},1e3)):this.errorMessage=r.data.message||"Login failed"}catch(t){console.error("Login error:",t),"ECONNABORTED"===t.code?this.errorMessage="Connection timeout. Please try again.":"Network Error"===t.message?this.errorMessage="Network error occurred. Please check your connection and try again.":this.errorMessage=t.response?.data?.message||"An error occurred during login."}finally{this.loading=!1}}}};
</script>